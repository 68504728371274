<div class="mini w-100 p-0 m-0 border-0 text-left {{type}}" [ngClass]="{'mini-sq': square}">
	<button class="mini-card w-100" (click)="launch()">
		<div class="card-title">
			<div class="triangle" *ngIf="mini?.NgssFlag"><div class="ngss"></div></div>
			<div class="text" [innerHtml]="mini?.title?.length > 22 ? (mini?.title | slice: 0:22) + '&hellip;' : mini?.title"></div>
		</div>
		<div class="image">
			<img [src]="mini?.thumbnail?.url" [attr.alt]="mini?.title" />
			<div class="tag" *ngIf="mini?.CardTopic?.title">{{ mini?.CardTopic?.title }}</div>
		</div>
	</button>
</div>
