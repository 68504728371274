import { Component, OnInit, EventEmitter, Output} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormArray,
	FormControl,
	Validators
} from '@angular/forms';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';

@Component({
	selector: 'app-search-form',
	templateUrl: './search-form.component.html',
	styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
	@Output() searchToggleEvent = new EventEmitter<boolean>();
	@Output() checkboxUpdate: EventEmitter<any> = new EventEmitter();
	languages: any;
	formalAudienceTypes: any[] = [];
	inFormalAudienceTypes: any[] = [];
	allAudienceTypes: any[] = [];
	browsers: any[] = [];
	operatingSystems: any[] = [];
	loaded: boolean = false;

	public explorings = [
		{
			name: 'Lessons',
			value: 'Lesson'
		},
		{
			name: 'Collections',
			value: 'Collection'
		},
		{
			name: 'Visualizations',
			value: 'Visualization'
		},
		{
			name: 'Contributed Content',
			value: 'Contributed Content'
		}
	];

	public createdBys = [
		{
			name: 'Infiniscope',
			value: 'Infiniscope'
		},
		{
			name: 'Collaboration',
			value: 'Collaboration'
		},
		{
			name: 'Community',
			value: 'Community'
		}
	];

	private _exploringInfo: any[] = [
		{
			value: [],
			message: 'Searching for Lessons'
		},
		{
			value: ['Lesson'],
			message: 'Searching for Lessons'
		},
		{
			value: ['Collection'],
			message: 'Searching for Collections'
		},
		{
			value: ['Lesson', 'Collection'],
			message: 'Searching for Lessons and Collections'
		}
	];

	public get educatorMode(): boolean {
		return true; //this.events.educatorMode;
	}

	searchform: FormGroup;
	constructor(
		public api: ApiService,
		private fb: FormBuilder,
		private events: EventService
	) {
		this.searchform = this.fb.group({
			checkArray: this.fb.array([], [Validators.required]),
			languageArray: this.fb.array([], [Validators.required]),
			audienceTypeArray: this.fb.array([], [Validators.required]),
			hardwareArray: this.fb.array([], [Validators.required]),
			exploringArray: this.fb.array(['Lessons', 'Collections','Visualizations', 'Contributed Content'], [Validators.required]),
			gradeRangeArray: new FormControl([]),
			searchTopic: new FormControl(''),
			popularTopics: new FormControl([]),
			sortBy: new FormControl('date'),
		});
	}

	public get exploringInfo() {
		const compareArrays = (a1, a2) => {
			if (a1.length != a2.length) return false;
			const compare = a1.filter((v1) => a2.indexOf(v1) < 0);
			return compare.length === 0;
		};
		const expInfo = this._exploringInfo.find((ei) =>
			compareArrays(ei.value, this.searchform.value.exploringArray)
		);
		return expInfo ? expInfo.message : '';
	}

	async ngOnInit() {
		await this.getLanguages();
		await this.getAudienceTypes();
		await this.getHardwares();
		this.selectingAlls();
		this.searchform.valueChanges.subscribe((values) => {
			this.checkboxUpdate.emit(values);
		});
	}

	// get languages data from api
	async getLanguages() {
		await this.api
			.get('languages', null)
			.toPromise()
			.then((res) => {
				this.languages = res;
			});
	}

	// get audience type data from api
	async getAudienceTypes() {
		await this.api
			.get('audience-types', null)
			.toPromise()
			.then((res) => {
				// this.formalAudienceTypes = res.filter((formal) => formal.formal);
				// this.inFormalAudienceTypes = res.filter((informal) => !informal.formal);
				this.allAudienceTypes = res;
			});
	}

	// get hardware data from api
	async getHardwares() {
		await this.api
			.get('hardwares', null)
			.toPromise()
			.then((res) => {
				this.browsers = res.filter((b) => b.type === 'operatingSystem');
				this.operatingSystems = res.filter((os) => os.type === 'browser');
				this.loaded = true;
			});
	}

	// on click toggle left side search bar
	searchStatus: boolean = false;
	searchToggle() {
		this.searchStatus = !this.searchStatus;
		this.searchToggleEvent.emit(this.searchStatus);
	}

	// on select all hardware get values and check all checkboxes
	checkHardware = true;
	selectAllHardware(e) {
		const hardArr: FormArray = this.searchform.get(
			'hardwareArray'
		) as FormArray;
		if (e === true || e.target.checked == true) {
			this.browsers.map(function (a) {
				hardArr.push(new FormControl(a.title));
			});
			this.operatingSystems.map(function (a) {
				hardArr.push(new FormControl(a.title));
			});
			this.checkHardware = true;
		} else {
			hardArr.clear();
			this.checkHardware = false;
		}
		this.filterArray();
	}

	// on select all language get values and check all checkboxes
	checkLanguage = false;
	selectAllLanguage(e) {
		const langArr: FormArray = this.searchform.get(
			'languageArray'
		) as FormArray;
		if (e === true || e.target.checked == true) {
			this.languages.map(function (a) {
				langArr.push(new FormControl(a.name));
				return a.name;
			});
			this.checkLanguage = true;
		} else {
			langArr.clear();
			this.checkLanguage = false;
		}
		this.filterArray();
	}

	// on select all audience get values and check all checkboxes
	checkAudience = true;
	selectAllAudience(e) {
		const audArr: FormArray = this.searchform.get(
			'audienceTypeArray'
		) as FormArray;
		if (e === true || e.target.checked == true) {
			// this.formalAudienceTypes.map(function (a) {
			// 	audArr.push(new FormControl(a.title));
			// 	return a.title;
			// });
			// this.inFormalAudienceTypes.map(function (a) {
			// 	audArr.push(new FormControl(a.title));
			// 	return a.title;
			// });
			this.allAudienceTypes.map(function (a) {
				audArr.push(new FormControl(a.title));
				return a.title;
			});
			this.checkAudience = true;
		} else {
			audArr.clear();
			this.checkAudience = false;
		}
		this.filterArray();
	}

	// on select all created by get values and check all checkboxes
	checkCreateby = false;
	selectAllCreateby(e) {
		const creatByArr: FormArray = this.searchform.get(
			'checkArray'
		) as FormArray;
		if (e === true || e.target.checked == true) {
			this.createdBys.map((c) => {
				creatByArr.push(new FormControl(c.value))
			})
			this.checkCreateby = true;
		} else {
			creatByArr.clear();
			this.checkCreateby = false;
		}
	}

	onSortChange(e) {
		if (e?.target?.value) {
			this.searchform.patchValue({sortBy: e.target.value});
		}
	}

	selectingAlls() {
		this.selectAllAudience(true);
		this.selectAllLanguage(true);
		this.selectAllHardware(true);
		this.selectAllCreateby(true);
		this.searchform.patchValue({sortBy: 'date'});
	}

	filterArray() {
		// passing filter object to API
	}
}
