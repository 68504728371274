import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionComponent } from './components/collection/collection.component';
import { LessonBannerModule } from 'src/app/shared/modules/lesson-banner/lesson-banner.module';
import { ContentDisplayerModule } from 'src/app/shared/modules/content-displayer/content-displayer.module';
// import { LessonContainerModule } from 'src/app/shared/modules/lesson-container/lesson-container.module';
import { LessonCardMiniModule } from 'src/app/shared/modules/lesson-card-mini/lesson-card-mini.module';
import { ContentFooterModule } from 'src/app/shared/modules/content-footer/content-footer.module';

@NgModule({
	declarations: [CollectionComponent],
	imports: [
		CommonModule,
		LessonBannerModule,
		ContentDisplayerModule,
		// LessonContainerModule,
		LessonCardMiniModule,
		ContentFooterModule
	],
	exports: [CollectionComponent]
})
export class CollectionModule {}
