import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
	Component,
	HostListener,
	Inject,
	NgZone,
	AfterContentChecked,
	OnInit,
	PLATFORM_ID
} from '@angular/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { ISubscription } from 'rxjs/Subscription';
import { environment } from 'src/environments/environment';
import { Lesson } from 'src/app/model/lesson.model';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { SeeMoreComponent } from 'src/app/shared/modules/see-more/see-more/see-more.component';
import { isPlatformBrowser } from '@angular/common';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';
import { InfoModalComponent } from 'src/app/shared/modules/info-modal/info-modal/info-modal.component';
import * as AWSCognito from 'amazon-cognito-identity-js';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	public educatorModeOn: boolean = this.event.educatorMode;
	private subscription: ISubscription;
	customOptions: OwlOptions = null;
	environment: any = environment;
	pageData: any = {};
	public slides: Lesson[] = [];
	public featuredLessons: Lesson[] = [];
	public windowScroll: number = 0;
	public forgotPasswordError: string;
	public successMessage: string;
	public resetPasswordError: string;
	public username: string;
	public verificationCode: string;
	public newPassword: string;
	public toggleResetPassword: boolean = false;
	public emailToSendReset: string;
	firstFlag: boolean = true;
	public cognitoUser: AWSCognito.CognitoUser;
	get authenticated(): boolean {
		return this.api.authenticated;
	}

	activeSlides: SlidesOutputData;

	constructor(
		private event: EventService,
		public api: ApiService,
		private alertService: AlertService,
		private dialog: MatDialog,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
		private router: Router,
		private ngZone: NgZone,
		private route: ActivatedRoute,
		private auth: AuthenticationService
	) {}

	ngOnInit(): void {
		this.subscription = this.event.currentData.subscribe((data: any) => {
			if (data.action === 'set_flag_mode') {
				this.educatorModeOn = data.dataobj.educatorModeOn;
				if (!this.firstFlag) {
					this.getpageDetail('home-page');
					this.getNewest();
				}
			}
		});
		this.getpageDetail('home-page');

		this.getNewest();

		this.firstFlag = false;

		this.route.queryParams.subscribe((params) => {
			this.toggleResetPassword = params.reset === 'true';
		});

		this.auth.refreshToken();

	}

	async getNewest() {
		let maxSlides = 10;
		let newSlides = [];
		// add lessons to slides
		await this.api.get('lessons?_limit=10&_sort=created_at:desc', null).toPromise()
			.then((res) => {
				this.ngZone.run(() => {
					newSlides = res.map((r) => Object.assign({}, r, { cardType: 'lesson' }));
				});
			});
		// if eduMode, add contributed content to slides
		if (this.educatorModeOn) {
			// maxSlides = 20;
			await this.api.get('contributed-contents?_limit=10&_sort=created_at:desc').toPromise()
				.then((res) => {
					this.ngZone.run(() => {
						res = res.map((r) => Object.assign({}, r, { cardType: 'contributed_content' }));
						newSlides = newSlides.concat(res);
					});
				}, (error) => { this.alertService.error(error); }
				);
		}
		// sort all of slides
		newSlides = newSlides.sort(function (a, b) {
			return (new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime());
		});
		// limit amount shown
		this.slides = newSlides.slice(0, maxSlides);
	}

	// Get slider data from api
	getpageDetail(slug: any) {
		this.api
			.get(slug)
			.toPromise()
			.then(
				(result) => {
					result.heroContent = result.heroContent.map((hc) => ({
						...hc,
						description: hc.description
					}));
					this.pageData = result;
					// this.featuredLessons = [];
					// this.loadFeaturedLessons(result.featured_lessons);
					// if (this.educatorModeOn) {
					// 	this.loadFeaturedContributed(result.featured_contributed);
					// 	this.loadFeaturedVisualizations(result.featured_visualizations);
					// } else {
					// 	result.heroContent = result.heroContent.filter(
					// 		(hc) => !hc.educatorOnly
					// 	);
					// }
					// this.loadHeroSlider();
				},
				(error) => {
					this.alertService.error(error);
				}
			);
	}

	private loadFeaturedLessons(lessons: Lesson[]) {
		if(lessons && lessons.length > 0) {
			const lessonIds = (lessons ?? []).map((l) => l.id);
			this.api
				.get('lessons?id=' + lessonIds.join('&id='))
				.toPromise()
				.then(
					(result) => {
						result = result.map((r) =>
							Object.assign({}, r, { cardType: 'lesson' })
						);
						this.featuredLessons = this.featuredLessons.concat(result);
						this.featuredLessons = this.featuredLessons.sort(function (a, b) {
							return (
								new Date(b.releaseDate).getTime() -
								new Date(a.releaseDate).getTime()
							);
						});
					},
					(error) => {
						this.alertService.error(error);
					}
				);
		}
	}

	private loadFeaturedContributed(lessons) {
		if(lessons && lessons.length > 0) {
			const lessonIds = (lessons ?? []).map((l) => l.id);
			this.api
				.get('contributed-contents?id=' + lessonIds.join('&id='))
				.toPromise()
				.then(
					(result) => {
						result = result.map((r) =>
							Object.assign({}, r, { cardType: 'contributed_content' })
						);
						this.featuredLessons = this.featuredLessons.concat(result);
						this.featuredLessons = this.featuredLessons.sort(function (a, b) {
							return (
								new Date(b.releaseDate).getTime() -
								new Date(a.releaseDate).getTime()
							);
						});
					},
					(error) => {
						this.alertService.error(error);
					}
				);
		}
	}

	private loadFeaturedVisualizations(lessons) {
		if(lessons && lessons.length > 0) {
			const lessonIds = (lessons ?? []).map((l) => l.id);
			this.api
				.get('visualizations?id=' + lessonIds.join('&id='))
				.toPromise()
				.then(
					(result) => {
						result = result.map((r) =>
							Object.assign({}, r, { cardType: 'visualization' })
						);
						this.featuredLessons = this.featuredLessons.concat(result);
						this.featuredLessons = this.featuredLessons.sort(function (a, b) {
							return (
								new Date(b.releaseDate).getTime() -
								new Date(a.releaseDate).getTime()
							);
						});
					},
					(error) => {
						this.alertService.error(error);
					}
				);
		}
	}

	loadHeroSlider() {
		this.customOptions = {
			loop: true,
			margin: 0,
			smartSpeed: 2000,
			autoplay: true,
			autoplayTimeout: 4000,
			autoplayHoverPause: true,
			dots: true,
			animateOut: 'fadeOut',
			animateIn: 'fadeIn',
			mouseDrag: false,
			touchDrag: true,
			navText: [
				'<span class="span-roundcircle left-roundcircle"><i class="fas fa-chevron-left"></i></span>',
				'<span class="span-roundcircle right-roundcircle"><i class="fas fa-chevron-right"></i></span>'
			],
			responsive: {
				0: {
					items: 1,
					nav: false
				},
				768: {
					items: 1,
					nav: true
				},

				1000: {
					items: 1,
					nav: true
				},
				1025: {
					items: 1,
					nav: true,
					loop: true
				}
			}
		};
	}

	// on click show model popup
	toggleModel: boolean = false;
	public async loginModelToggle() {
		// this.toggleModel = !this.toggleModel;
		const dialog = await this.dialog.open(SeeMoreComponent, {
			width: '600px',
			panelClass: 'addTagModal'
		});
		dialog.afterClosed().subscribe((lesson) => {});
	}

	// Unsubscribe event before leave component
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	@HostListener('window:scroll', ['$event']) handleScroll() {
		if (isPlatformBrowser(this.platformId)) {
			this.windowScroll =
				this.window.nativeWindow.scrollY / this.window.nativeWindow.outerHeight;
		}
	}

	public get submitLessonStyle() {
		return {
			bottom: this.windowScroll >= 1 ? '8rem' : '2rem'
		};
	}

	goToSubmitLesson() {
		this.router.navigate(['/submit-content']);
	}

	public fetchUsername() {
		this.forgotPasswordError = '';
		if (!this.emailToSendReset) {
			this.forgotPasswordError = 'You must provide your email.';
			return;
		}
		this.api
			.post('emails/username', { email: this.emailToSendReset })
			.toPromise()
			.then(
				(data) => this.resetPassword(data.username),
				(err) => (this.forgotPasswordError = 'That email does not exist.')
			);
	}

	public resetPassword(username) {
		this.forgotPasswordError = '';
		// const poolData = { UserPoolId: xxxx, ClientId: xxxx };
		// userPool is const userPool = new AWSCognito.CognitoUserPool(poolData);
		this.username = username;
		localStorage.setItem('reset-username', this.username);

		let poolData = {
			UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
			ClientId: environment.cognitoAppClientId // Your client id here
		};

		// setup cognitoUser first
		this.cognitoUser = new AWSCognito.CognitoUser({
			Username: username,
			Pool: new AWSCognito.CognitoUserPool(poolData)
		});

		// call forgotPassword on cognitoUser
		this.cognitoUser.forgotPassword({
			onSuccess: (result) => {
				this.emailToSendReset = '';
				console.log('success');
				this.successMessage =
					'Code sent! Please check your email and do not close this window.';
			},
			onFailure: (err) => {
				console.log(err);
				this.forgotPasswordError = 'Something went wrong, please try again.';
			}
		});
	}

	// confirmPassword can be separately built out as follows...
	public confirmPassword() {
		let poolData = {
			UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
			ClientId: environment.cognitoAppClientId // Your client id here
		};

		this.cognitoUser = new AWSCognito.CognitoUser({
			Username: this.username || localStorage.getItem('reset-username'),
			Pool: new AWSCognito.CognitoUserPool(poolData)
		});

		return new Promise((resolve, reject) => {
			this.cognitoUser.confirmPassword(
				this.verificationCode,
				this.newPassword,
				{
					onFailure: (err) => {
						console.log(err);
						this.resetPasswordError =
							'Something went wrong. Please confirm you are using the correct code and a different password that contains a minimum of 9 characters with at least one special character and both uppercase and lowercase.';
					},
					onSuccess: () => {
						this.toggleResetPassword = false;
						this.dialog.open(InfoModalComponent, {
							width: '600px',
							panelClass: 'addTagModal',
							data: {
								title: 'Password Reset Successfully',
								msg:
									'Your password has now been reset and you can try to login again.'
							}
						});
					}
				}
			);
		});
	}
}
