import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Lesson } from 'src/app/model/lesson.model';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event/event.service';
import { ApiService } from 'src/app/services/API/api.service';
import { MiniMetricsService } from 'src/app/services/minimetrics/minimetrics.service';
import { MatDialog } from '@angular/material/dialog';
import { SocialMediaModalComponent } from '../../social-media-modal/social-media-modal/social-media-modal.component';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

@Component({
	selector: 'app-lesson-card',
	templateUrl: './lesson-card.component.html',
	styleUrls: ['./lesson-card.component.scss'],
})
export class LessonCardComponent implements OnInit {
	// @Input() lesson: Lesson;
	@Input() type: string;
	@Input() lesson: any;
	@Input() right: boolean = false;
	@Input() left: boolean = false;
	@Input() smaller: boolean = false;
	@Input() isMobile: boolean = false; // this.DeviceDetectorService.isMobile()
	@Input() likes: number = 0;
	@Input() bookmarks: number = 0;
	public auth: boolean = false;
	public apiUrl = environment.api_url;
	public openCard: boolean = false;
	public flippedCard: boolean = false;
	private flipTime: number = 0;
	public video: boolean = false;
	public liked: boolean = false;
	private minimetricId: string;
	private bannerType: string = 'lesson';
	private urlDetails: string = null;

	constructor(
		private api: ApiService,
		private event: EventService,
		private miniMetricService: MiniMetricsService,
		private dialog: MatDialog,
		private window: WindowRef,
		private deviceService: DeviceDetectorService,
	) {}

	ngOnInit(): void {
		this.auth = this.api.authenticated;
		this.video = Boolean(this.lesson?.YouTubeId);
		this.bannerType  = this.type !== 'collection' ? this.bannerType : this.type;
		this.urlDetails = '/' + this.type + '/' + this.lesson?.id;

		if (this.auth && this.lesson?.id > 0) {
			this.api
				.get( `minimetrics?${this.miniMetricService.getQuery(this.bannerType)}=${this.lesson?.id}&user.id=${this.api.user.id}&type=like` )
				.subscribe((res) => {
					res = res || [];
					this.liked = res.length > 0;
					if (this.liked) this.minimetricId = res[0].id;
				});
		}
		
		// if (this.type === 'collection' && this.lesson?.id > 0) {
		// 	this.lesson['title'] = this.lesson?.Title || this.lesson?.title;
		// 	this.lesson['description'] = this.lesson?.Description || this.lesson?.description;
		// 	this.lesson['thumbnail'] = this.lesson?.thumbnail || this.lesson?.cardImage || {url: '/assets/images/image.jpg'};
		// }
	}

	private removeTags(str) {
		if (!str?.length) return '';
		else str = str.toString();
		return str.replace(/(<([^>]+)>)/ig, '');
	}
	private truncateByChars(str, maxChars) {
		if (!str?.length || str.length <= maxChars) return str || '';
		let words = str.split(' ');
		let result = '';
		let currentLength = 0;
		for (let word of words) {
			if (currentLength + word.length + 1 <= maxChars) { // +1 for space
				result += word + ' ';
				currentLength += word.length + 1;
			} else break;
		}
		return result.trim() + '...';
	}
	public getBlurb(str) {
		return this.truncateByChars(this.removeTags(this.lesson?.description) || this.lesson?.title, 102);
	}

	// public closeCard(e) {
	// 	if (Date.now() - this.flipTime <= 500) return;
	// 	const rollBack = (element) => {
	// 		if (
	// 			element.classList &&
	// 			(element.classList.contains('cardFrontContainer') ||
	// 				element.classList.contains('cardBackContainer'))
	// 		)
	// 			return true;
	// 		if (element.parentElement) return rollBack(element.parentElement);
	// 		else return false;
	// 	};
	// 	if (!rollBack(e.toElement)) this.openCard = false;
	// }

	// public toggleFlip() {
	// 	this.flippedCard = !this.flippedCard;
	// 	this.flipTime = Date.now();
	// }

	public reduceLanguages(lesson) {
		return lesson?.languages.map((l) => l.name.substr(0, 2)).join(', ');
	}

	public toggleLike() {
		if (this.lesson?.id < 1) { return; }
		this.miniMetricService.toggleLike(this.bannerType, this.lesson.id).subscribe((res) => {
			this.liked = res > 0;
			this.likes += res;
		})
	}
	
	public shareLesson() {
		if (this.lesson?.id < 1) { return; }
		if (navigator && navigator.share) { navigator.share({ url: this.lesson.publicUrl }); }
		else {
			const dialogRef = this.dialog.open(SocialMediaModalComponent, {
				width: '600px',
				panelClass: 'addTagModal',
				data: { share: this.lesson.publicUrl }
			});
		}
	}

	public playVideo(ev) {
		if(!this.lesson?.YouTubeId) { console.error('No associated video'); return; }
		this.event.globalEvent({
			action: 'playLessonVideo',
			dataobj: {
				title: this.lesson?.title,
				videoId: this.lesson?.YouTubeId,
				event: ev
			}
		});
		// console.log('lesson-card e:playLessonVideo, videoId:', this.lesson?.YouTubeId);
	}

	public goToDetails() {
		const url = this.urlDetails;
		this.window.nativeWindow.open(url);
	}

	public launch(ev) {
		const url = this.lesson?.publicUrl;
		if (!url) { console.error('No launch URL found.'); return; }
		if (!this.isMobile) { this.window.nativeWindow.open(url, '_blank'); }
		else { // show Desktop Preferred modal
			this.event.globalEvent({
				action: 'lessonCardLaunch',
				dataobj: { url: url, event: ev }
			});
		}
	}
}