<div class="collectionContainer">
	<app-lesson-banner [lesson]="collection" *ngIf="collection" [bannerType]="'collection'" [bookmarks]="bookmarks" [likes]="likes" [lesson]="collection" role="header"></app-lesson-banner>
	<div class="container" *ngIf="collection">
		<div class="row col-12 px-0 mx-0 my-3 collectionInformation" role="main">
			<div class="col-lg-3 col-md-4 border-right">
				<app-content-displayer title="Collection Info" type="minimetrics"
					[content]="{ duration: collection?.duration || '30 min', watches: watches, shares: shares }"></app-content-displayer>
				<app-content-displayer title="Lesson Resources" type="documents" [content]="collection?.LessonResources">
				</app-content-displayer>
				<app-content-displayer title="Alignment Documents" type="documents" [content]="collection?.AlignmentDocuments">
				</app-content-displayer>
				<app-content-displayer title="Technical Details" type="tech" [content]="collection?.hardwareCompatibility">
				</app-content-displayer>
				<app-content-displayer title="Languages" type="text"
					[content]="breakDownElements(collection?.languages, 'name', '\n')"></app-content-displayer>
			</div>
			<div class="row col-lg-9 col-md-8">
				<div class="col-12 col-md-6 col-lg-6">
					<app-content-displayer title="Collection Description" type="text" [content]="collection?.Description">
					</app-content-displayer>
					<app-content-displayer title="Central Question" type="text" [content]="collection?.CentralQuestion">
					</app-content-displayer>
					<app-content-displayer title="Instructional Objective" type="text"
						[content]="collection?.InstructionalObjective"></app-content-displayer>
					<app-content-displayer title="Prerequisites" type="enum" [content]="collection?.Prerequisites">
					</app-content-displayer>
					<div class="content">
						<h3>Topics</h3>
						<div class="topic-tag" [ngClass]="{ 'educator-mode-auth': educatorMode && authenticated }">
							<ul>
								<li *ngFor="let topic of topics" (click)="searchForTopic(topic)">
									<button class="btn" disabled *ngIf="!educatorMode || !authenticated">{{ topic.title }}</button>
									<button class="btn" *ngIf="educatorMode && authenticated">{{ topic.title }}</button>
								</li>
							</ul>
						</div>
					</div>		
				</div>
				<div class="col-12 col-md-6 col-lg-6">
					<h3 *ngIf="lessons?.length">Lessons</h3>
					<div class="row p-0 m-0 mini-sq" [ngClass]="{'hidden': !lessons?.length}">
						<div *ngFor="let mini of lessons" class="col-6 px-1">
							<app-lesson-card-mini square="true" type="lesson" [mini]="mini"></app-lesson-card-mini>
						</div>
					</div>
					<h3 *ngIf="visualizations?.length">Visualizations</h3>
					<div class="row p-0 m-0 mini-sq" [ngClass]="{'hidden': !visualizations?.length}">
						<div *ngFor="let mini of visualizations" class="col-6 px-1">
							<app-lesson-card-mini square="true" type="visualization" [mini]="mini"></app-lesson-card-mini>
						</div>
					</div>
					<h3 *ngIf="contributedContent?.length">Contributed Content</h3>
					<div class="row p-0 m-0 mini-sq" [ngClass]="{'hidden': !contributedContent?.length}">
						<div *ngFor="let mini of contributedContent" class="col-6 px-1">
							<app-lesson-card-mini square="true" type="content" [mini]="mini"></app-lesson-card-mini>
						</div>
					</div>

					<h3 *ngIf="nasaResources?.length">NASA Resources</h3>
					<div [ngClass]="{'hidden': !nasaResources?.length}" class="lessonContainer nasaResources row col-12 mx-0 px-0">
						<div class="row col-12 mx-0 px-0 my-2">
							<div class="col-lg-3 col-md-6 mx-0 p-2 col-lg-6" *ngFor="let nasaR of nasaResources; let i = index">
								<button class="cardContainer w-100 p-0 m-0 border-0 text-left" (click)="goToPage(nasaR?.URL)">
									<div class="cardThumbnailContainer px-2 pb-2 pt-1">
										<div class="title mb-3 font-weight-bold text-black">
											{{
												nasaR?.Title?.length > 22
												? (nasaR?.Title | slice: 0:22) + '&hellip;'
												: nasaR?.Title
											}}
										</div>
										<div class="content imageContainer smaller" style="background-image: url('{{nasaR?.Image?.url}}');"></div>
									</div>
								</button>
							</div>
					  	</div>
					</div>

				</div>
				<div class="col-12 mx-0" role="contentinfo">
					<div class="col-12 mx-0 px-0 pt-3 border-top">
						<!-- <app-content-footer [techCards]="techCards" [requireAuthentication]="true" [contentId]="getInt(collectionId)" [contentType]="'collection_model'" [content]="collection"></app-content-footer> -->
						<div class="row">
							<div class="col-lg-12">
								<div class="content">
									<h3>Teach with This Collection</h3>
									<p>By creating a section, you'll be able to preview the content, enroll your students, and/or customize this collection.</p>
								</div>
							</div>
						</div>
						<div class="learn-card-container">
							<div class="row">
								<h3>Do you want to&hellip;</h3>
								<div class="col-lg-4">
									<div class="learn-card">
										<h3 class="learn-card-title">Preview</h3>
										<div class="learn-card-body">
											<ul>
												<li>see what is included in this collection?</li>
												<li>see an overview of the content?</li>
												<li>open individual components?</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="learn-card">
										<h3 class="learn-card-title">Enroll</h3>
										<div class="learn-card-body">
											<ul>
												<li>enroll your learners in the collection?</li>
												<li>monitor your learners' progress?</li>
												<li>integrate the collection into your pre-existing online course?</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="learn-card">
										<h3 class="learn-card-title">Customize</h3>
										<div class="learn-card-body">
											<ul>
												<li>add content?</li>
												<li>delete content?</li>
												<p class="warning-blurb">
													<b>*</b> Customizing will allow you to add materials that were not created by Infiniscope.
													By clicking below, you agree to review all materials for content and accuracy before using them.
												</p>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-12">
									<div class="learn-card-footer">
										<button class="btn" [disabled]="!authenticated"
										  [ngClass]="{ 'btn-blue': authenticated, 'btn-grey': !authenticated }"
										  (click)="handleTorusClick()">
											Create a Section
										</button>
										<span class="text-required" *ngIf="!authenticated">* Login Required</span>
										<div style="text-align: center; font-size: 80%;" *ngIf="authenticated">
											<a target="_blank" href="https://round-currency-221.notion.site/Enrolling-Learners-in-a-Collection-Under-Development-23a6306101994d13840e432b9dc1fc48">
												How-To Guide
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- Model to Torus -->
<div class="modal fade modal-auth" [ngClass]="toggleModelTorus ? 'show' : ''" id="modal-torus"
	[ngStyle]="{ display: toggleModelTorus == true ? 'block' : 'none' }">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h2 tabindex="-1" class="modal-title" id="exampleModalLongTitle">How would you like to enroll?</h2>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" (click)="toggleModelTorus = !toggleModelTorus">&times;</span></button>
			</div>
			<div class="modal-body">
				<div class="login-form-main">
					<div class="login-info">
						<p>Choose the enrollment option that best fits your classroom. Click the button below to start enrolling your students.</p>
						<p style="color: #cc2200;">
							<strong>Important Note:</strong><br/>
							If you are using an LMS, you or your site administrator will
							need to configure the LMS connection before enrollment. You may want to consider choosing "I don't have an LMS"
							if you need to enroll your students before a site administrator can complete the configuration.
						</p>
					</div>
					<button class="btn"
					  [disabled]="!collection?.torusUrl"
					  [ngClass]="collection?.torusUrl ? 'btn-blue' : 'btn-grey'"
					  (click)="goToPage(collection?.torusUrl)">
						I don't have an LMS or I use Google Classroom
					</button>
					<button class="btn"
					  [disabled]="!collection?.lmsUrl"
					  [ngClass]="collection?.lmsUrl ? 'btn-blue' : 'btn-grey'"
					  (click)="goToPage(collection?.lmsUrl)">
						I use an LMS like Canvas, Moodle, or Blackboard
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <div [ngClass]="toggleModelTorus ? 'modal-backdrop fade show' : ''"></div> -->
<!-- End Model to Torus -->
