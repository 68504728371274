<div class="lessonContainer row col-12 mx-0 px-0" #lessonContainer>
	<div class="title row col-12 mx-0 px-0 my-2 pl-2" *ngIf="sliderTitle">
		<h2 class="col-9 pl-0 text-white my-0 explore-btn-ref">
			<div class="title-label"><b>{{ sliderTitle }}</b></div>
			<div class="title-descr">{{ curTypeDesc }}</div>
		</h2>
		<div class="col-3 text-right">
			<button class="btn" *ngIf="!auth" (click)="loginModalToggle()">Sign in for full access</button>
		</div>
	</div>
	<div class="sliderArrowContainer row col-1 m-0 p-0" *ngIf="useSlider && getLessons().length >= curSize">
		<div class="relContainer">
			<button class="half-circle prev" [disabled]="getLessons().length < curSize" (click)="moveSlider(false)" aria-label="previous">
				<div class="icon"><i class="fas fa-chevron-left align-self-center mx-auto"></i></div>
			</button>
		</div>
	</div>
	<div class="sliderContainer row col-10 mx-0 px-0" *ngIf="useSlider">
		<app-lesson-card class="col-lg-3 col-md-6 col-12 mx-0 p-2 custom_lesson_card"
			*ngFor="let lesson of getLessons(); let i = index"
			[lesson]="lesson" [type]="type" [smaller]="smaller" [isMobile]="isMobile"
			[ngStyle]="{
				left: getCardSpacing() * (i - sliderIndex) + '%',
				transition: sliderTransition ? sliderTransitionTime + 's ease' : 'none'
			}"
			[left]="i - sliderIndex === 0 && getCardSpacing() < 100"
			[right]="i - sliderIndex === 3 && getCardSpacing() < 100"
		></app-lesson-card>
	</div>
	<div class="sliderArrowContainer row col-1 m-0 p-0" *ngIf="useSlider && getLessons().length >= curSize">
		<div class="relContainer">
			<button class="half-circle next" [disabled]="getLessons().length < curSize" (click)="moveSlider(true)" aria-label="next">
				<div class="icon"><i class="fas fa-chevron-right align-self-center mx-auto"></i></div>
			</button>
		</div>
	</div>
	<div class="row col-12 mx-0 px-0 my-2" *ngIf="!useSlider && isMobile">
		<app-lesson-card class="col-12 col-xxl-2 col-lg-3 col-md-6 mx-0 p-2"
			*ngFor="let lesson of mobileLessons()"
			[lesson]="lesson" [type]="type" [smaller]="smaller" [isMobile]="isMobile"
		></app-lesson-card>
		<button class="btn btn-primary mt-3" (click)="loadMoreCards()"
			*ngIf="moreCards">Show more +</button>
	</div>
	<div class="row col-12 mx-0 px-0 my-2" *ngIf="!useSlider && !isMobile">
		<app-lesson-card class="col-xxl-2 col-lg-3 col-md-6 col-12 mx-0 p-2"
			*ngFor="let lesson of getLessons(); let i = index"
			[lesson]="lesson" [type]="type" [smaller]="smaller" [isMobile]="isMobile"
			[left]="i % convertSpacing(getCardSpacing()) === 0 && getCardSpacing() < 100"
			[right]="i % convertSpacing(getCardSpacing()) === convertSpacing(getCardSpacing()) - 1 && getCardSpacing() < 100"
		></app-lesson-card>
		<!-- <app-lesson-card class="col-xxl-2 col-lg-3 col-md-6 col-12 mx-0 p-2"
			*ngFor="let lesson of sortedLessons; let i = index"
			[lesson]="lesson" [type]="type" [smaller]="smaller" [isMobile]="isMobile"
			[left]="i % convertSpacing(getCardSpacing()) === 0 && getCardSpacing() < 100"
			[right]="i % convertSpacing(getCardSpacing()) === convertSpacing(getCardSpacing()) - 1 && getCardSpacing() < 100"
		></app-lesson-card> -->
	</div>
</div>
