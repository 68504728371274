import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonCardMiniComponent } from './lesson-card-mini/lesson-card-mini.component';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [LessonCardMiniComponent],
	imports: [CommonModule, RouterModule],
	exports: [LessonCardMiniComponent]
})
export class LessonCardMiniModule {}
