import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event/event.service';
import { ApiService } from 'src/app/services/API/api.service';
import { MatDialog } from '@angular/material/dialog';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

@Component({
	selector: 'app-lesson-card-mini',
	templateUrl: './lesson-card-mini.component.html',
	styleUrls: ['./lesson-card-mini.component.scss'],
})
export class LessonCardMiniComponent implements OnInit {
	// @Input() lesson: Lesson;
	@Input() type: string;
	@Input() mini: any;
	@Input() square: boolean = false;
	public apiUrl = environment.api_url;

	constructor(
		private api: ApiService,
		private event: EventService,
		private dialog: MatDialog,
		private window: WindowRef,
	) {}

	public launch() {
		if (!this.mini.id) { console.error('ID not found. Cannot launch link.'); }
		let url = '/' + this.type + '/' + this.mini.id;
		this.window.nativeWindow.open(url, '_self');
	}

	ngOnInit(): void {
		// console.log('mini', this.mini);
		this.type = this.type || this.mini.objectType || 'lesson';
	}
}