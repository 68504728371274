<main role="main" class="flex-shrink-0 middle-section">
	<div class="middle-inner">
		<section class="hero-banner-section">
			<app-hero-image-slider [heroBanners]="pageData.heroContent"></app-hero-image-slider>
		</section>

		<section class="featured-section">
			<div class="explore-pane">
				<div class="card-pane">
					<!-- <app-lesson-container sliderTitle="Featured" [lessons]="featuredLessons" [clipHorizontally]="true"></app-lesson-container> -->
					<!-- <app-lesson-container sliderTitle="Newest" [lessons]="slides" [clipHorizontally]="true"></app-lesson-container> -->
					<div class="row">
						<div class="col text-center"><h2>Explore</h2></div>
					</div>
					<div class="row p-0">
						<div class="offset-1 col-10">
							<div class="row p-0 item-panes">
								<div class="col-12 col-lg">
									<div class="item-pane">
										<div class="item-card">
											<div class="title">Content Library</div>
											<div class="image"><img src="/assets/images/card-search-library.jpg" alt="" /></div>
											<div class="blurb">Browse or search our content library for NGSS-designed digital lessons, virtual field trips, visualizations and contributed content.</div>
											<div class="cta"><a href="/search"><button class="btn btn-primary cutstom">Search Content</button></a></div>
										</div>
									</div>
								</div>
								<div class="col-12 col-lg">
									<div class="item-pane">
										<div class="item-card">
											<div class="title">Create a Virtual Field Trip</div>
											<div class="image"><img src="/assets/images/card-virtual-tour.jpg" alt="" /></div>
											<div class="blurb">Discover Tour It, a free browser-based platform empowering you to create immersive virtual field trips for learners of all ages.</div>
											<div class="cta"><a href="/page/tour-it"><button class="btn btn-primary cutstom">Take me to Tour It</button></a></div>
										</div>
									</div>
								</div>
								<div class="col-12 col-lg">
									<div class="item-pane">
										<div class="item-card">
											<div class="title">Build a Lesson</div>
											<div class="image"><img src="/assets/images/card-build-lesson.jpg" alt="" /></div>
											<div class="blurb">Create your own digital lessons and problem sets  that guide students with personalized pathways and formative feedback.</div>
											<div class="cta"><a href="/page/simple-author"><button class="btn btn-primary cutstom">Show me more</button></a></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</main>

<!-- Modal: Log In -->
<div class="modal fade modal-auth" [ngClass]="toggleModel ? 'show' : ''" id="modal-signin-home"
	[ngStyle]="{ display: toggleModel == true ? 'block' : 'none' }">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title" id="exampleModalLongTitle">
					Interested in seeing more?
				</h2>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" (click)="loginModelToggle()">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-form-main">
					<div class="login-info">
						<p>
							Login or create an account for full access to simulations,
							community created content, and collaboration tools.
						</p>
					</div>
					<app-login title="loginModel" forgotPasswordRightAlignment="true"></app-login>
				</div>
			</div>
		</div>
	</div>
</div>
<div [ngClass]="toggleModel ? 'modal-backdrop fade show' : ''"></div>

<!-- Modal: Reset Password -->
<div class="modal fade modal-auth" [ngClass]="toggleResetPassword ? 'show' : ''" id="modal-reset-pwd"
	[ngStyle]="{ display: toggleResetPassword == true ? 'block' : 'none' }">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title" id="exampleModalLongTitle">
					Reset your password
				</h2>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" (click)="toggleResetPassword = !toggleResetPassword">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-form-main">
					<input type="email" name="email" [(ngModel)]="emailToSendReset" placeholder="Enter your email address"/>
					<div class="invalid-feedback">
						{{ forgotPasswordError }}
					</div>
					<div class="valid-feedback">
						{{ successMessage }}
					</div>
					<button (click)="fetchUsername()"
					class="btn btn-blue code-btn">Send Reset Code</button>
					
					<input type="text" name="verification" [(ngModel)]="verificationCode" placeholder="Enter your verification code"/>
					<input type="password" name="newpassword" [(ngModel)]="newPassword" placeholder="Enter your new password"/>
					<div *ngIf="resetPasswordError" class="invalid-feedback">
						<div *ngIf="resetPasswordError">
							{{ resetPasswordError }}
						</div>
					</div>
					<button (click)="confirmPassword()"
					class="btn btn-blue">Confirm Password</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div [ngClass]="toggleResetPassword ? 'modal-backdrop fade show' : ''"></div>