import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-grade-range',
	templateUrl: './grade-range.component.html',
	styleUrls: ['./grade-range.component.scss']
})
export class GradeRangeComponent implements OnInit {
	@Input() searchform: FormGroup;
	@Input() faIcon: string = null;

	public gradeRanges = [
		{
			name: 'K-4',
			value: 'K-4'
		},
		{
			name: '5-8',
			value: '5-8'
		},
		{
			name: '9-12',
			value: '9-12'
		},
		{
			name: '13+',
			value: '13+'
		}
	];

	constructor() {}

	ngOnInit(): void {
		this.searchform.controls.gradeRangeArray.setValue(
			this.gradeRanges.map((t) => t.value)
		);
	}

	onGradeChange(e) {
		if (e.target.checked) {
			this.searchform.controls.gradeRangeArray.setValue([
				...this.searchform.controls.gradeRangeArray.value,
				e.target.value
			]);
		} else {
			this.searchform.controls.gradeRangeArray.setValue(
				this.searchform.controls.gradeRangeArray.value.filter(
					(g) => g != e.target.value
				)
			);
		}
	}
}
