<ol class="custom-grouping"
	[ngClass]="{
		'group-checkbox': id !== 'exploring',
		expl: id === 'exploring',
		'pl-0': noPadding
	}"
	[formGroup]="searchform"
>
	<ng-container *ngFor="let iterator of data; let i = index">
		<li
			class="custom-control custom-checkbox"
			*ngIf="iterator.name == 'Collections' || iterator.name == 'Lessons' || educatorMode"
		>
			<input
				type="checkbox"
				class="custom-control-input"
				[id]="id + i"
				(change)="onCheckboxChange($event)"
				value="{{
					id === 'formal' ||
					id === 'informal' ||
					id === 'browser-hardware' ||
					id === 'os-hardware'
						? iterator.title
						: iterator.name
				}}"
				[checked]="checkeds"
			/>
			<label class="custom-control-label" [for]="id + i">{{
				id === 'formal' ||
				id === 'informal' ||
				id === 'browser-hardware' ||
				id === 'os-hardware'
					? iterator.title
					: iterator.name
			}}</label>
		</li>
	</ng-container>
</ol>
